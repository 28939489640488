/** Dependencies */
import axios from 'axios'

class Fetch {
  constructor(options) {
    this.instance = axios.create({
      withCredentials: true,
      ...options
    })

    this.instance.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401 && window.location.pathname !== "/login") {
          window.location.href = "/login"

          return false
        }
        return Promise.reject(error)
      }
    )
  }

  get(...params) {
    return this.instance.get(...params)
  }

  post(...params) {
    return this.instance.post(...params)
  }

  put(...params) {
    return this.instance.put(...params)
  }
}

export default Fetch
