/** Dependencies */
import React from "react";
import { Link, NavLink } from "react-router-dom";

/** Stylesheets */
import "./Header.scss";

/** Assets */
import logo from "../../assets/img/logo.svg";
// import notIcon from "../../assets/img/notIcon.svg";
import logout from "../../assets/img/logout.svg";

/** Services */
import ArzumOkkaB2BService from "../../services/ArzumOkkaB2BService";

/** Contexts */
import {withUser} from "../../context/userContext";

function Header({user}) {
  let notNumber = 1;

  const logoutHandler = async () => {
    try {
      await ArzumOkkaB2BService.Logout()

      window.location.href = '/login'
    } catch (error) {
      alert('Fatal error!')
    }
  }

  return (
    <>
      <header className="container-fluid">
        <div className="bar">
          <nav className="navbar navbar-expand-lg navbar-trans navbar-light px-0 px-lg-4 mt-lg-5">
            <Link className="navbar-brand" to="/">
              <img className="logo" src={logo} alt="Arzum OKKA" />
            </Link>

            {/*<div className="notification d-block d-sm-none ml-auto mr-3">*/}
            {/*  <div className="num">{notNumber}</div>*/}
            {/*  <img src={notIcon} alt="Arzum OKKA" />*/}
            {/*</div>*/}
            <div
              className="logout d-block d-sm-none"
              data-toggle="modal"
              data-target="#createModal"
            >
              <img className="mr-3" src={logout} alt="Arzum OKKA" />
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav d-lg-none">
                <div
                  className="user my-3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className='photo'>{user.FirstName.charAt(0).toUpperCase()}</div>
                  <div className='name'>
                    <button>
                      Hello, {`${user.FirstName} ${user.LastName}`}
                    </button>
                  </div>
                </div>
                {/*<div className="dropdown-menu">*/}
                {/*  <button className="dropdown-item" type="button">*/}
                {/*    Action*/}
                {/*  </button>*/}
                {/*  <button className="dropdown-item" type="button">*/}
                {/*    Another action*/}
                {/*  </button>*/}
                {/*  <button className="dropdown-item" type="button">*/}
                {/*    Something else here*/}
                {/*  </button>*/}
                {/*</div>*/}
                <NavLink className="nav-item nav-link" to="/companies">
                  Companies
                </NavLink>
                <NavLink className="nav-item nav-link" to="/devices">
                  Devices
                </NavLink>
              </div>
            </div>

            <div className="right ml-lg-auto mt-2 mt-lg-0">
              {/*<div className="notification mr-lg-5 d-none d-lg-block">*/}
              {/*  <div className="num">{notNumber}</div>*/}
              {/*  <img src={notIcon} alt="Arzum OKKA" />*/}
              {/*</div>*/}
              <div className="logout d-none d-lg-block" onClick={logoutHandler}>
                Logout <img className="ml-3" src={logout} alt="Arzum OKKA" />
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default withUser(Header)
