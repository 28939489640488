/** Dependencies */
import React, {useEffect, useState, createContext, useContext} from 'react'

/** Services */
import ArzumOkkaB2BService from "../services/ArzumOkkaB2BService";

const UserContext = createContext()
const {Provider, Consumer} = UserContext;

function UserProvider({children}) {
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const getUserInfo = async () => {
    setLoading(true)
    try {
      const {data} = await ArzumOkkaB2BService.GetUserInfo();
      setUser(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setHasError(true)
    }
  }

  useEffect(() => {
    getUserInfo()
  }, [])

  return <Provider value={{user, userLoading: loading, userHasError: hasError}}>{children}</Provider>
}


function withUser(Component) {
  return function ({children, ...others}) {
    return (
      <Consumer>
        {({user}) => (
          <Component {...others} user={user}>{children}</Component>
        )}
      </Consumer>
    )
  }
}

export {UserProvider, Consumer as UserConsumer, withUser}
