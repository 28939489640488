/** Utilities */
import Fetch from "../utilities/Fetch";

const fetcher = new Fetch({
  // baseURL: 'http://dev.inovatink.com:5002/api',
   baseURL: 'https://b2b.arzumokka.com/api',
  // baseURL: 'http://localhost:5002/api',
})

class ArzumOkkaB2BService {
  static Login(email, password) {
    return fetcher.post('/v1/auth/login', {
      Email: email,
      Password: password
    })
  }

  static ResetPassword(email) {
    return fetcher.post('/v1/auth/resetPassword', {
      Email: email
    })
  }

  static Logout() {
    return fetcher.post('/v1/auth/logout')
  }

  static GetUserInfo() {
    return fetcher.get('/v1/auth/me')
  }

  static GetCompaniesByCompany(companyId) {
    return fetcher.get(`/v1/companies/${companyId}`)
  }

  static GetCompanyDevicesByCompany(companyId, limit = 20) {
    return fetcher.get(`/v1/companies/${companyId}/devices`, {
      params: {
        limit
      }
    })
  }

  static GetDevice(deviceId) {
    return fetcher.get(`/v1/devices/${deviceId}`)
  }

  static GetDeviceLatestTimeSeries(deviceId, keys = 'CoffeeConsumption,CoffeeMode,State,Latitude,Longitude,Error') {
    return fetcher.get(`/v1/devices/${deviceId}/latestTimeSeries`, {
      params: {
        keys
      }
    })
  }

  static GetDeviceTimeSeries(deviceId, startDateTime, endDateTime, bys = 'attribute,dateTime', keys = 'CoffeeConsumption,CoffeeMode,State,Error') {
    return fetcher.get(`/v1/devices/${deviceId}/timeSeries`, {
      params: {
        startDateTime,
        endDateTime,
        keys,
        bys
      }
    })
  }

  static GetAllDevicesForReports() {
    return fetcher.get('/v1/reports/allDevices');
  }
}

export default ArzumOkkaB2BService
