/** Dependencies */
import React, {Suspense, lazy} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.bundle";

/** Stylesheets */
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Layout from "./components/Layout/Layout";

/** Contexts */
// import {UserProvider, UserConsumer} from "./context/userContext";

/** Pages */
const Login = lazy(() => import("./pages/Login/Login"))
const NotFound = lazy(() => import("./pages/NotFound/NotFound"))
const Companies = lazy(() => import("./pages/Companies/Companies"));
const Devices = lazy(() => import("./pages/Devices/Devices"));
const Device = lazy(() => import("./pages/DevicePage/Device"));
const Reports = lazy(() => import("./pages/Reports/Reports"));


export default function App(props) {
  function jwtCheck() {
    try {
      const jwtCookie = Cookies.get('jwt');

      if (!jwtCookie) {
        throw new Error('error')
      }

      const jwt = jwtDecode(jwtCookie)

      if (Date.now() >= jwt.exp * 1000) {
        throw new Error('error')
      }

      return true
    } catch (error) {
      return false
    }
  }

  return (
      <Suspense fallback={"Yükleniyor..."}>
        <Router>
          {jwtCheck() ? (
              <Layout>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/companies"/>
                  </Route>
                  <Route path="/devices/:deviceId" component={Device}/>
                  <Route path="/devices" component={Devices}/>
                  <Route path="/reports" component={Reports}/>
                  <Route path="/companies/:companyId?" component={Companies}/>
                  <Route component={NotFound}/>
                </Switch>
              </Layout>
          ) : (
              <Switch>
                <Route exact path="/">
                  <Redirect to="/login"/>
                </Route>
                <Route path="/login" component={Login}/>
                <Route>
                  <Redirect to="/login"/>
                </Route>
              </Switch>
          )}
        </Router>
      </Suspense>
  )
}
