/** Dependencies */
import React from 'react';
import {NavLink} from 'react-router-dom';

/** Stylesheets */
import './Sidebar.scss';

import poweredByImg from "../../assets/img/powered-by-inovatink-light.svg";


/** Contexts */
import {withUser} from "../../context/userContext";

function Sidebar({user}) {
    return (
        <div id='sidebar' className='d-none d-lg-block col-2'>
            <div
                className='user'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
                <div className='photo'>{user.FirstName.charAt(0).toUpperCase()}</div>
                <div className='name'>
                    <button>
                        Hello, {`${user.FirstName} ${user.LastName}`}
                    </button>
                </div>
            </div>
            {/*<div className='dropdown-menu'>*/}
            {/*  <button className='dropdown-item' type='button'>*/}
            {/*    Action*/}
            {/*  </button>*/}
            {/*  <button className='dropdown-item' type='button'>*/}
            {/*    Another action*/}
            {/*  </button>*/}
            {/*  <button className='dropdown-item' type='button'>*/}
            {/*    Something else here*/}
            {/*  </button>*/}
            {/*</div>*/}
            <nav>
                <NavLink to='/companies'>Companies</NavLink>
                <NavLink to='/devices'>Devices</NavLink>
                <NavLink to='/reports'>Reports</NavLink>
                {/*<NavLink to='/calendar'>Calendar</NavLink>*/}
                {/*<NavLink to='/contacts'>Contacts</NavLink>*/}
                {/*<NavLink to='/settings'>Settings</NavLink>*/}
            </nav>
            <div className="poweredByImage">
                <img src={poweredByImg} />
            </div>
        </div>
    )
}

export default withUser(Sidebar);
