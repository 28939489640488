/** Dependencies */
import React from 'react';

/** Components */
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

/** Contexts */
import {UserProvider, UserConsumer} from "../../context/userContext";

export default function Layout(props) {
  const {children} = props

  return (
    <UserProvider>
      <UserConsumer>
        {({userLoading, userHasError}) => (
          <>
            {!userLoading && !userHasError && (
              <>
                <Header/>
                <div className="container-fluid pl-lg-0 mb-lg-5">
                  <div className="row">
                    <Sidebar/>
                    <div className="col-12 col-lg-10">
                      <div className="bg-shape"/>
                      <div className="wrapper">
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </UserConsumer>
    </UserProvider>
  );
}
